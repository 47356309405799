import { Cmp } from ":mods";
import { Accordion } from "./accordion";
import { CSSStyler } from "./css-styler";
import { For, Show } from "solid-js";

export function AccordionPage(props: {
  object: any;
  title: string;
  arrayKey: string;
  newNamePrefix: string;
  newObjectTemplate?: any;
  defaultStyle?: any;
  newOptions?: { name: string; template: any }[];
  onNewOption?: (option: { name: string; template: any }) => void;
  onUpdate: (items: any, deletingIndex?: number) => void;
  onSelected: (key: any, item: any, index: any) => void;
  onStyle: (item: any) => void;
}) {
  if (!props.object?.style && props.defaultStyle) {
    props.onStyle?.(props.defaultStyle);
  }
  return (
    <Accordion
      title={props.title}
      disabled={!props.object}
      tabs={[
        {
          title: "new",
          element: (
            <div class="w-full h-full flex flex-row flex-wrap text-center">
              <Show when={props.newOptions} fallback={"no templates to choose from"}>
                <For each={props.newOptions}>
                  {(item) => {
                    return (
                      <div
                        class="flex flex-col  text#p w-50% h-fit cursor-pointer items-center px-2 py-1 capitalize text-0.45rem"
                        onClick={() => props.onNewOption?.(item)}
                      >
                        <p class="bg#p w-24px h-24px"></p>
                        <span>{item.name}</span>
                      </div>
                    );
                  }}
                </For>
              </Show>
            </div>
          ),
        },
        {
          title: props.arrayKey,
          element: (
            <Cmp.List.Editable
              each={props.object?.[props.arrayKey] ?? []}
              class="list-editable"
              processValue={{
                set: (value, exisitng, index, newEl) =>
                  !newEl
                    ? {
                        ...exisitng,
                        name: value,
                      }
                    : { ...props.newObjectTemplate, name: `unnamed ${props.newNamePrefix}` },
                get: (value, index) => (!value.name ? `${props.newNamePrefix} ${index.constant}` : value.name),
              }}
              events={{
                update: (items) => {
                  props.onUpdate?.(items);
                },
                delete: (items, kvp, index) => {
                  props.onUpdate?.(items, index);
                },
                reorder: (items) => {
                  props.onUpdate?.(items);
                },
                add: (items) => {
                  props.onUpdate?.(items);
                },
                clicked: (key, value, index) => {
                  // console.log("clicked with :: ", key, " :: ", value);
                  value.name = key as any;
                  props.onSelected?.(key, value, index);
                },
              }}
            />
          ),
        },
        {
          title: "style",
          element: (
            <CSSStyler
              style={props.object?.style}
              class="list-editable list-editable-side-panel"
              onChange={(style) => props.onStyle?.(style)}
            />
          ),
        },
      ]}
    />
  );
}
